<template>
  <v-container fluid class="down-top-padding">
    <BaseBreadcrumb :title="page.title" :module_name="page.name" :current="record.name"/>
    <v-row>
      <v-col>
        <base-tab-panel
            :module_name="page.name"
            :record="record"
            @change="save"
            @archive="archive"
            @export="exportCompany"
            :loading="loading"
            :model="page.model">
          <template v-slot:main_fields>
            <div style="width: 100%" class="text-center">
              <v-file-input
                  v-if="!record.logo"
                  accept="image/*"
                  :label="$store.getters.translate('logo')"
                  show-size
                  @change="saveImage"
                  v-model="file"/>
              <v-layout v-else align-center justify-center column fill-height>
                <v-flex row align-center>
                  <v-img position="center" max-width="200" contain :src="record.logo.full_path"/>
                </v-flex>
                <v-btn text color="red" class="mt-2" @click="deleteImage">
                  <v-icon color="error">mdi-trash-can</v-icon>
                </v-btn>
              </v-layout>
            </div>
            <template v-for="field in usual_fields">
              <base-field
                  :key="field.name"
                  :value="record[field.name]"
                  :field="field"
                  @change="usualFieldDataChanged"/>
            </template>
            <base-tags v-if="tags_required" v-model="record.tags" :module_name="page.name"/>
          </template>
          <template v-slot:module_fields>
            <template v-for="field in custom_fields">
              <base-field
                  :key="field.name"
                  :value="record.custom_fields[field.name]"
                  :field="field"
                  @change="customFieldDataChanged"/>
            </template>
            <email-addresses v-model="record.emailaddresses" email_type="personal" label="emailaddress" @change="save"/>
            <v-row>
              <v-col>
                <v-text-field
                    :class="'ma-0 pt-'+$store.getters.lineheight"
                    :label="$store.getters.translate('ascription')"
                    v-model="record.ascription"
                    validate-on-blur/>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <base-addresses v-model="record.addresses" @change="save" address_type="visiting" label="contact_address" mapID="map1"/>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-switch class="mt-0" :label="$store.getters.translate('billing_address_the_same')" v-model="hide_billing_address"/>
              </v-col>
            </v-row>
            <v-row v-if="!hide_billing_address">
              <v-col>
                <base-addresses v-model="record.addresses" @change="save" address_type="billing" label="billing_address" mapID="map2"/>
              </v-col>
            </v-row>
          </template>
        </base-tab-panel>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-expansion-panels v-model="panel" multiple>
          <base-table-includable ref="contacts_table" :page="page" :parent_module_id="record.id" relation_name="contacts" @create="createContact" @edit="editContact" @change="load"/>
          <base-table-includable ref="candidates_table" :page="page" :parent_module_id="record.id" relation_name="candidates" @edit="editCandidate" @change="load"/>
          <base-table-includable ref="notes_table" :page="page" :parent_module_id="record.id" relation_name="notes" @create="createNote" @edit="editNote" @change="load"/>
        </v-expansion-panels>
      </v-col>
    </v-row>
    <edit-note modal_name="edit_note" :module_name="page.name" :model_id="record.id" ref="edit_note" @document_preview="preview" @refresh="load" :model_type="$models[page.name]"/>
    <create-note modal_name="create_note" :module_name="page.name" :model_id="record.id" ref="create_note" @refresh="load"/>
    <create-person modal_name="create_person" action="create_contact" ref="create_person" user_type="contact" @refresh="load"/>
    <document-edit modal_name="document_edit" ref="document_edit"/>
  </v-container>
</template>

<script>
import BaseTableIncludable from "../../components/commonComponents/BaseTableIncludableData";
import BaseAddresses from "../../components/commonComponents/BaseAddresses";
import BaseTabPanel from "../../components/commonComponents/BaseTabPanel";
import CreatePerson from "../people/Create";
import CreateNote from "../notes/Create";
import EditNote from "../notes/Edit";
import BaseBreadcrumb from "../../components/commonComponents/BaseBreadcrumb";
import DocumentEdit from "../documents/Edit";
import helpFunctions from "../../plugins/helpFunctions";

export default {
  components: {
    DocumentEdit,
    BaseTableIncludable,
    BaseAddresses,
    BaseTabPanel,
    CreatePerson,
    CreateNote,
    EditNote,
    BaseBreadcrumb,
  },
  data() {
    return {
      page: {
        title: this.$store.getters.translate("companies"),
        name: "companies",
        model: "company",
      },
      record: {
        custom_fields: {}
      },
      usual_fields: [],
      custom_fields: [],
      tags_required: false,
      loading: false,
      file: null,
      key: 0,
      tab: 0,
      hide_billing_address: true,
    };
  },
  created() {
    this.load();
  },
  methods: {
    load() {
      this.loading = true;
      this.$http
        .get(this.$store.getters.appUrl + "v2/" + this.page.name + "/" + this.$route.params.id)
        .then((response) => {
          this.record = response.data;
          if (this.record.custom_fields == null || Array.isArray(this.record.custom_fields) || typeof this.record.custom_fields != "object") {
            this.record.custom_fields = {};
          }
          this.record.addresses.forEach((address) => {
            if (address.primary) {
              this.hide_billing_address = true;
            }
          });
          this.updateIncludableTables();
          this.getFields();
        })
        .catch((error) => {
          this.$toasted.error(error);
          this.loading = false;
        });
    },
    updateIncludableTables() {
      this.$refs.candidates_table.records = this.record.candidates;
      this.$refs.contacts_table.records = this.record.contacts;
      this.$refs.notes_table.records = this.record.notes;
      this.$refs.candidates_table.selected = [];
      this.$refs.contacts_table.selected = [];
      this.$refs.notes_table.selected = [];
    },
    save() {
      if(!this.loading) {
        let error = false;
        this.usual_fields.forEach((field) => {
          if (field.mandatory && field.field_type !== 'boolean' && (!this.record[field.name] || this.record[field.name] === '')) {
            this.$toasted.error(this.$store.getters.translate("required_field") + ": " + this.$store.getters.translate(field.name));
            error = true;
          }
        });
        if (!error) {
          if (this.record.phonenumber && this.record.phonenumber.charAt(0) !== '+') {
            this.$toasted.error(this.$store.getters.translate("international_number_error"));
          } else {
            this.loading = true;
            this.$http
                .patch(this.$store.getters.appUrl + "v2/" + this.page.name + "/" + this.record.id, this.record)
                .then((response) => {
                  this.loading = false;
                  if (response.status == 200) {
                    this.$toasted.success(this.$store.getters.translate("success"));
                    this.load();
                  } else {
                    var errorMessage = response.data.message;
                    var errors = Object.keys(response.data.message);
                    this.$toasted.error([errorMessage[errors[0]]]);
                  }
                })
                .catch((error) => {
                  if (error.response.status === 403) {
                    this.$toasted.error(this.$store.getters.translate('no_access'))
                  } else {
                    this.$toasted.error(error);
                  }
                  this.loading = false;
                });
          }
        }
      }
    },
    archive() {
      this.$confirm(this.$store.getters.translate("are_you_sure")).then((res) => {
        if (res) {
          this.loading = true;
          this.$http
            .delete(this.$store.getters.appUrl + "v2/" + this.page.name + "/" + this.record.id)
            .then(() => {
              this.$router.push("/" + this.page.name);
              this.loading = false;
              this.$toasted.success(this.$store.getters.translate("success"));
            })
            .catch((error) => {
              if (error.response.status === 403) {
                this.$toasted.error(this.$store.getters.translate('no_access'))
              } else {
                this.$toasted.error(error);
              }
              this.loading = false;
            });
        }
      });
    },
    exportCompany(export_type) {
      this.loading = true;
      let html = helpFunctions.exportCompany(export_type, this.record, this.usual_fields, this.custom_fields);
      if(export_type === 'excel') {
        this.exportTableToExcel(html);
      }
      else {
        this.exportTableToPdf(html);
      }
    },
    exportTableToPdf(html) {
      this.$http
          .post(this.$store.getters.appUrl + "v2/pdf/generate-pdf", {html: html, file_name: this.record.name}, {responseType: "blob"})
          .then((response) => {
            const blob = new Blob([response.data], {type: "application/pdf"});
            const link = document.createElement("a");
            link.href = URL.createObjectURL(blob);
            link.download = this.record.name + ".pdf";
            link.click();
            URL.revokeObjectURL(link.href);
            this.loading = false;
          });
    },
    exportTableToExcel(html) {
      const link = document.createElement("a");
      link.href = 'data: application/vnd.ms-excel, ' + html.replace(/ /g, '%20');
      link.download = this.record.name + ".xls";
      link.click();
      this.loading = false;
    },
    editNote(item) {
      this.$refs.edit_note.openModal('edit_note');
      this.$refs.edit_note.load(item.id);
    },
    editContact(item) {
      this.$router.push("/contacts/" + item.id);
    },
    editCandidate(item) {
      this.$router.push("/candidates/" + item.id);
    },
    createContact() {
      this.$refs.create_person.openModal('create_person');
      this.$refs.create_person.record.add_to_companies = [this.record.id];
    },
    createNote() {
      this.$refs.create_note.openModal('create_note');
    },
    preview(file) {
      this.$refs.document_edit.openModal('document_edit');
      this.$refs.document_edit.load(file);
    },
    reload() {
      this.key += 1;
    },
    saveImage() {
      this.loading = true;
      const formData = new FormData();
      formData.append("logo", this.file, "name.jpeg");
      this.$http
        .post(
          this.$store.getters.appUrl + "v2/companies/file?id=" + this.record.id,
          formData,
          {
            headers: { "Content-Type": "multipart/form-data" },
          }
        )
        .then(() => {
          this.loading = false;
          this.load();
        })
        .catch(function (error) {
          this.$toasted.error(error);
        });
    },
    deleteImage() {
      this.$http
        .post(this.$store.getters.appUrl + "v2/companies/file/delete?id=" + this.record.id)
        .then(() => {
          this.loading = false;
          this.load();
          this.file = null;
        })
        .catch(function (error) {
          if (error.response.status === 403) {
            this.$toasted.error(this.$store.getters.translate('no_access'))
          } else {
            this.$toasted.error(error);
          }
          this.loading = false;
        });
    },
    usualFieldDataChanged(data) {
      this.record[data[0]] = data[1];
    },
    customFieldDataChanged(data) {
      this.record.custom_fields[data[0]] = data[1];
    },
    async getFields() {
      this.$http
          .get(this.$store.getters.appUrl + "v2/fields?source=site&show_on_update=true&model_type=" + this.page.model)
          .then((response) => {
            this.custom_fields = [];
            this.usual_fields = [];
            response.data.data.forEach((field) => {
              if (field.custom_field == 1) {
                this.custom_fields.push(field);
              }
              else if(field.name === 'tags') {
                this.tags_required = true;
              }
              else {
                if(field.field_type === 'select_single' || field.field_type === 'select_multiple') {
                  for (let i = 0; i < field.available_options.length; i++) {
                    field.available_options[i] = {
                      text: this.$store.getters.translate(field.available_options[i]),
                      value: field.available_options[i],
                    };
                  }
                }
                this.usual_fields.push(field);
              }
            });
            this.loading = false;
          })
          .catch((error) => {
            if (error.response.status === 403) {
              this.$toasted.error(this.$store.getters.translate('no_access'))
            } else {
              this.$toasted.error(error);
            }
            this.loading = false;
          });
    },
  },
  computed: {
    panel: {
      set(value) {
        let payload = {
          module_name: this.page.name,
          value: value,
        };
        this.$store.dispatch("setPanel", payload);
      },
      get() {
        return this.$store.getters.getPanel(this.page.name);
      },
    },
  },
  watch: {
    hide_billing_address: {
      handler() {
        if (this.hide_billing_address) {
          this.record.addresses.forEach((address) => {
            if (address.type === 'billing') {
              this.$http
                  .delete(this.$store.getters.appUrl + "v2/addresses/" + address.id)
                  .then(() => {
                    this.record.addresses = this.record.addresses.filter((address2) => address2.id !== address.id);
                  })
                  .catch((error) => {
                    this.$toasted.error(error);
                    this.loading = false;
                  });
            }
            else if(address.type === 'visiting' && !address.primary) {
              address.primary = 1;
              this.$http
                  .patch(this.$store.getters.appUrl + "v2/addresses/" + address.id, address)
                  .then(() => {
                    this.$toasted.success(this.$store.getters.translate("success"));
                  })
                  .catch((error) => {
                    this.$toasted.error(error);
                    this.loading = false;
                  });
            }
          });
        }
        else {
          this.record.addresses.forEach((address) => {
            if (address.type === 'visiting' && address.primary) {
              address.primary = 0;
              this.$http.patch(this.$store.getters.appUrl + "v2/addresses/" + address.id, address);
            }
          });
        }
      },
    },
  },
};
</script>